<template>
    <a-card style="background-color: #fff;">
        <div>
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="角色名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                                <a-input v-model="queryData.name" placeholder="请输入角色名称" />
                            </a-form-item>
                        </a-col>
                        <!-- <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="权限标识" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                                <a-input v-model="queryData.code" placeholder="请输入权限标识" />
                            </a-form-item>
                        </a-col> -->
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="角色状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                                <a-select v-model="queryData.status" placeholder="请选择状态" option-label-prop="label"
                                    allowClear>
                                    <a-select-option v-for="item in [{ name: '正常', id: 1 }, { name: '停用', id: 2 }]"
                                        :key="item.id" :value="item.id" :label="item.name">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-button @click="showModal('add')">新增角色</a-button>
                    </a-row>
                </div>
            </a-form>
        </div>
        <div style="margin-top:10px;">
            <a-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                <div slot="status" slot-scope="text">
                    <a-tag v-if="text == 1" color="green">正常</a-tag>
                    <a-tag v-if="text == 2" color="red">停用</a-tag>
                </div>
                <template slot="createTime" slot-scope="record">{{ record ? formatDateMin(record) : '' }}</template>
                <div slot="action" slot-scope="text,record">
                    <a @click="showModal('edit', record)" style="margin-left: 5px;">编辑</a>
                </div>
            </a-table>
        </div>

        <FormModal v-if="isModalVisible" :visible="isModalVisible" :confirmLoading="confirmLoading" :form="form"
            :formData="formData" @cancel="handleCancel" @submit="submit" />
    </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import FormModal from './FormModal.vue'
const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center' },
    { title: '角色名称', dataIndex: 'name', align: 'center' },
    // { title: '权限标识', dataIndex: 'code', align: 'center' },
    { title: '显示顺序', dataIndex: 'orderNum', align: 'center' },
    { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
    { title: '状态', dataIndex: 'status', align: 'center', scopedSlots: { customRender: 'status' } },
    { title: '操作', align: 'center', scopedSlots: { customRender: 'action' } },
]

export default {
    name: 'QueryList',
    components: { FormModal },
    data() {
        return {
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 1000,
            },
            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            currentPage: 1,
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {
                name: ''
                // 根据具体需要添加更多字段
            },
            form: this.$form.createForm(this) // Ant Design Vue 的表单对象
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        dateChange(date, dateString) {
            if (date) {
                this.queryData.dateMin = dateString[0].toString() + ' 00:00:00';
                this.queryData.dateMax = dateString[1].toString() + ' 23:59:59';
                this.dateValue = date;
            } else {
                this.queryData.dateMin = '';
                this.queryData.dateMax = '';
                this.dateValue = null;
            }
        },
        showModal(type, data) {
            // 根据类型区分是新增还是编辑
            if (type === 'add') {
                this.formData = {
                    name: '',
                    // code:'',
                    orderNum:'',
                    status:undefined,
                }
                this.isModalVisible = true; // 打开模态框
            } else {
                let newData = JSON.parse(JSON.stringify(data));
                this.formData = newData;
                this.isModalVisible = true; // 打开模态框
            }

        },
        handleCancel() {
            this.isModalVisible = false; // 取消操作
        },
        submit(data) {
            if (data.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/role/update', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('更新成功！')
                        this.isModalVisible = false;
                        this.$set(this.formData, 'salesReturnProvinceCode', this.formData.provinceCode);
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/role/save', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('保存成功！')
                        this.isModalVisible = false;
                    }
                })
            }
        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 1000
            }
            this.dateValue = ''
            this.searchCity = [];
            this.searchProv = [];
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/role/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return result
        },        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>
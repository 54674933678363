<template>
    <a-modal :width="600" :maskClosable="false" :title="formData.id ? '编辑' : '新增'" :visible="visible"
        @cancel="handleCancel" @ok="handleSubmit" :confirm-loading="confirmLoading">
        <a-form-model ref="ruleForm" :model="formData" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item ref="name" label="角色名称" prop="name">
                <a-input v-model="formData.name" placeholder="请输入角色名称" />
            </a-form-model-item>
            <!-- <a-form-model-item ref="code" label="权限标识" prop="code">
                <a-input v-model="formData.code" placeholder="请输入权限标识" />
            </a-form-model-item> -->
            <a-form-model-item ref="orderNum" label="显示顺序" prop="orderNum">
                <a-input v-model="formData.orderNum" placeholder="请输入显示顺序" />
            </a-form-model-item>
            <a-form-model-item ref="status" label="角色状态" prop="status">
                <a-select v-model="formData.status" placeholder="请选择状态" option-label-prop="label">
                    <a-select-option v-for="item in [{ name: '正常', id: 1 }, { name: '停用', id: 2 }]" :key="item.id"
                        :value="item.id" :label="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item required label="菜单权限">
                <a-tree v-model="menuList" checkable :expanded-keys="expandedKeys" :auto-expand-parent="true"
                    :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect" />
            </a-form-model-item>

        </a-form-model>
    </a-modal>
</template>

<script>
import { request } from '@/utils/request'
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        confirmLoading: {
            type: Boolean,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '请输入角色名称', trigger: 'blur' }
                ],
                // code: [
                //     { required: true, message: '请输入权限标识', trigger: 'blur' },
                //     // { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                // ],
                roles: [
                    { required: true, message: '请选择角色', trigger: 'change' }
                ],
                status: [
                    { required: true, message: '请选择状态', trigger: 'change' }
                ],
                orderNum: [
                    { required: true, message: '请输入显示顺序', trigger: 'blur' }
                ],

            },
            expandedKeys: [],
            autoExpandParent: true,
            checkedKeys: [],
            treeData: [],
            selectedKeys: [],
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            menuList: [],
        }
    },
    watch: {
        checkedKeys(val) {
            console.log('onCheck', val);
            this.menuList = val;
        },
    },
    mounted() {
        // 新增只查树
        request(process.env.VUE_APP_API_BASE_URL + 'srm/menu/treeSelect', 'post', {}).then((res) => {
            this.treeData = res.data.data;
            this.expandedKeys = this.getAllKeys(this.treeData);
            if (this.formData.id) {
                // 编辑查另一个接口
                request(process.env.VUE_APP_API_BASE_URL + 'srm/role/roleTreeSelect/' + this.formData.id, 'post', {}).then((res) => {
                    this.treeData = res.data.data.menus
                    let checkedKeys = res.data.data.checkedKeys;
                    this.treeData.forEach(menu => {
                        if (checkedKeys.includes(menu.key)) {
                            menu.children.forEach(child => {
                                if (!checkedKeys.includes(child.key)) {
                                    const index = checkedKeys.indexOf(menu.key);
                                    if (index > -1) {
                                        checkedKeys.splice(index, 1); // 移除checkedKeys中的quSFp5nod2
                                    }
                                }
                            });
                        }
                    });
                    this.checkedKeys = checkedKeys
                    this.expandedKeys = this.getAllKeys(this.treeData);
                    // this.menuList = res.data.data.checkedKeys
                })
            }
        })
    },
    methods: {
        getAllKeys(data) {
            let keys = [];
            data.forEach(item => {
                keys.push(item.key);
                if (item.children && item.children.length) {
                    keys = keys.concat(this.getAllKeys(item.children));
                }
            });
            return keys;
        },
        onExpand(expandedKeys) {
            console.log('onExpand', expandedKeys);
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },
        onCheck(checkedKeys, { checkedNodes }) {
            console.log('onCheck', checkedKeys);
            // 筛选出叶子节点的 key
            const leafKeys = checkedNodes
                .filter(node => !node.children) // 仅保留叶子节点
                .map(node => node.key);
            this.checkedKeys = leafKeys.checked; // 更新绑定的 checkedKeys
        },
        // onCheck(checkedKeys) {
        //     console.log('onCheck', checkedKeys);
        //     this.checkedKeys = checkedKeys;
        // },
        onSelect(selectedKeys, info) {
            console.log('onSelect', info);
            this.selectedKeys = selectedKeys;
        },
        handleCancel() {
            // 处理取消操作
            this.$emit('cancel');
        },
        handleSubmit() {
            // 处理表单提交
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    console.log(this.menuList, 'this.menuList.checked')
                    if (this.menuList.length == 0) {
                        this.$message.error('请选择菜单权限');
                        return false;
                    }
                    this.formData.menuList = this.menuList
                    console.log(this.formData.menuList, 'sss')
                    this.formData.menuList.forEach(item => {
                        console.log(item, 'iten')
                        this.treeData.forEach(tree => {
                            if (tree.children) {
                                console.log(tree.children, 'tree.children')
                                tree.children.forEach(treeSon => {
                                    if (treeSon.key == item) {
                                        if (!this.formData.menuList.includes(treeSon.pKey)) {
                                            this.formData.menuList.push(treeSon.pKey)
                                        }
                                    }
                                })
                            }
                        })
                    })
                    this.$emit('submit', this.formData)
                }
            });
        },
    }
};
</script>
<style>
.title-text {
    font-weight: 700;
    font-size: 16px;
    color: #333;
}
</style>